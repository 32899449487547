import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import LinearProgress from '@material-ui/core/LinearProgress';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            <Link color="primary" href="https://sonomatech.com/">
                Sonoma Technology, Inc.
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            username: "",
            password: "",
            error: false,
            isMenuOpen: false
        }
    }

    handleToggleMenu() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        })

    }

    handleSubmit(e) {

        e.preventDefault();

        let error = false;

        if (this.state.username === "" || this.state.password === "") {
            error = true;
        }

        this.setState({
            error: error
        });

        if (!error) {
            this.props.onSubmit(this.state.username, this.state.password);
        }


    }

    handleChange(e) {
        this.setState({
            [e.target.id] : e.target.value,
            error: false
        })
    }

    render () {

        const containerStyle = {
            marginTop: "5%"
        }

        const loginDivStyle = {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        };

        const hasError = (this.state.error || this.props.isLoginError);

        return (

            <Container style={containerStyle} component="main" maxWidth="xs">


                <Card>

                    <CardContent>


                        <CssBaseline/>

                        <div style={loginDivStyle}>

                            <Avatar>
                                <LockOutlinedIcon/>
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>


                            <form onSubmit={this.handleSubmit} noValidate>

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="username"
                                    label="Insight Username"
                                    name="username"
                                    autoComplete="username"
                                    autoFocus
                                    error={(hasError)}
                                    onChange={this.handleChange}
                                />

                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    error={(hasError)}
                                    onChange={this.handleChange}
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={this.props.isLoading}
                                >
                                    {
                                        (this.props.isLoading)
                                        ? <span>Signing in...</span>
                                        : <span>Sign in</span>
                                    }
                                </Button>

                                {
                                    (this.props.isLoading)
                                    ? <LinearProgress  />
                                    : null
                                }

                            </form>

                        </div>

                        <Box mt={8}>
                            <Copyright/>
                        </Box>

                    </CardContent>

                </Card>

            </Container>
        );

    }


};

export default LoginForm;
