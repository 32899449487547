import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {logout, changeSelectedViewType, selectAppUi} from "../../store/appSlice";
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import LockIcon from '@material-ui/icons/Lock';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import SaveDslChangesButton from "../inventory/SaveDslChanges";
import Drawer from '@material-ui/core/Drawer';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventIcon from '@material-ui/icons/Event';
import {ContentViewEnum} from "../../config/APP_CONSTANTS";
import { createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';

import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    }
}));


export default function AppHeader() {

    const classes = useStyles();

    const [isMenuDrawerOpen, toggleMenuDrawer] = React.useState(false);

    const uiState = useSelector(selectAppUi);

    const dispatch = useDispatch();

    return (
        <React.Fragment>

            <AppBar position="static" color={"transparent"}>

                <div style={{height: "4px"}}>
                    {
                        (uiState.isLoading)
                            ? <LinearProgress />
                            : null
                    }
                </div>

                <Toolbar>

                    <Typography variant="h6" className={classes.title}>
                        <IconButton onClick={() => toggleMenuDrawer(true)} edge="start" className={classes.menuButton} color="inherit" >
                            <MenuIcon fontSize={"large"} />
                        </IconButton>
                    </Typography>

                    <SaveDslChangesButton />

                </Toolbar>

            </AppBar>


            <Drawer anchor={"left"} open={isMenuDrawerOpen} onClose={() => toggleMenuDrawer(false)}>
                <div

                    role="presentation"
                    onClick={() => toggleMenuDrawer(false)}
                    onKeyDown={() => toggleMenuDrawer(false)}
                >

                    <List>
                        <ListItem button key={"logOutMenuBtn"} onClick={() => dispatch(logout())} >
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Log Out"} />
                        </ListItem>
                    </List>

                    <Divider />

                    <List>

                        <ListItem button key={"inventoryManagementMenuBtn"} onClick={() => dispatch(changeSelectedViewType(ContentViewEnum.DSL_INVENTORY_MANAGEMENT))}>
                            <ListItemIcon>
                                <PlaylistAddCheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Inventory Management"} />
                        </ListItem>

                        {/*TODO: Implement EventHeatmap*/}
                        <ListItem button key={"EventHeatmapMenuBtn"} disabled={true}  onClick={() => dispatch(changeSelectedViewType(ContentViewEnum.DSL_EVENT_HEATMAP))}>
                            <ListItemIcon>
                                <EventIcon />
                            </ListItemIcon>
                            <ListItemText primary={"Event Heatmap"} secondary={"(not implemented yet)"} />
                        </ListItem>


                    </List>

                </div>
            </Drawer>


        </React.Fragment>
    );
}