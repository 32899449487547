export const loadFromLocalStorage = (target) => {

    try {

        const serializedState = localStorage.getItem(target);

        if (!serializedState) {
            return null;
        }

        return JSON.parse(serializedState);

    } catch (ex) {
        console.error("Unable to load persisted state from localStorage for target {}", target);
        return null;
    }

};

export const persistToLocalStorage = (target, payload) => {

    try {

        localStorage.setItem(
            target,
            JSON.stringify(payload)
        );

    } catch (ex) {
        console.error("Unable to persist the app state to localStorage");
    }

};

export const removeFromLocalStorage = (target) => {

    localStorage.removeItem(target);

}