import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import grey from '@material-ui/core/colors/grey';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import moment from 'moment';
import Button from '@material-ui/core/Button';
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

import {lighten, withStyles, makeStyles} from '@material-ui/core/styles';

import {stableSort, getComparator} from './DslTableUtil';
import {EnhancedTableHeader} from "./TableHeader";
import {DslDetails} from './DslDetails';

import {MaintenanceModeOptionsEnum} from "../../config/APP_CONSTANTS";

import {
    selectAppData,
    selectAppUi,
    selectAuth,
    selectDsl,
    toggleMaintenanceModeCheck,
    toggleMissingDataCheck,
    changeSelectedDsl,
    changeSelectedDataStream,
} from '../../store/appSlice';


const getMdcDisplayProps = (mdc, stats=null) => { // Missing data checks display props

    let props = {
        cellStyle : {
            backgroundColor: green[300],
            cursor: "pointer"
        },
        label: "Enabled",
    };

    if (!mdc.isEnabled) {
        props.cellStyle.backgroundColor = grey[300];
        props.label = "Disabled";
    }

    else if (stats && stats.isLate) {
        props.cellStyle.backgroundColor = red[300];
        props.label = "Late";
    }
    return props;
};


const getMmcDisplayProps = () => {  // maintenance mode check display props
    return {
        cellStyle : {
            cursor: "pointer",
            paddingLeft: 10,
            display: "flex",
            flexDirection: "column"
        }
    };
};

const NormalOperationsButton = withStyles((theme) => ({
    contained: {
        color: theme.palette.getContrastText(green[300]),
        backgroundColor: green[300],
        '&:hover': {
            backgroundColor: green[500]
        }
    },
    outlined: {
        borderColor: green[300],
    }
}))(Button);


const useRowStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    highlight:
        theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
        },
    title: {
        flex: '1 1 100%',
    },
}));

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 100,
    },
    backdrop: {
        // zIndex: theme.zIndex.drawer + 1,
        // color: '#fff',
        backgroundColor: '#f5f5f5',
        display: 'flex',
        flexWrap: 'wrap',
    },
    title: {
        margin: theme.spacing(4, 0, 2),
    },
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);


function Row(props) {

    const dispatch = useDispatch();
    const { dsl, mdcDisplayProps, rowProps, mmcDisplayProps } = props;
    const classes = useRowStyles();

    const selectedDsl = useSelector(selectDsl);

    const handleTableRowClick = (e, payload) => {

        if (payload.open) {

            if (payload.dsl.stats.statsByDataStream.length > 0) {

                const defaultSelectedDs = { // default to fist dataStream in the list
                    dataStream: payload.dsl.stats.statsByDataStream[0],
                    startDtMillis: moment().subtract(1, "day").valueOf(),
                    endDtMillis: moment.now().valueOf()
                }

                dispatch(changeSelectedDataStream(defaultSelectedDs));
            }

            dispatch(changeSelectedDsl(payload.dsl.id));

        } else {
            dispatch(changeSelectedDsl(null));
        }


    }

    const isOpen = (selectedDsl.dslId && selectedDsl.dslId === dsl.id);

    return (
        <React.Fragment>
            <TableRow hover={true}  >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={(e) => handleTableRowClick(e, {open: !isOpen, dsl: dsl})}>
                        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    {dsl.name}
                </TableCell>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Missing Data Check</Typography>
                            <p>
                                <b>{"Enabled - "}</b>
                                {'When enabled, the operations staff will receive an email notification if any data associated with this instrument becomes stale beyond a predefined time window (usually 30 minutes).'}
                            </p>
                            <p>
                                <b>{"Disabled - "}</b>
                                {'When disabled, the system will not check for the staleness of the data, silencing notifications for this instrument.'}
                            </p>
                        </React.Fragment>
                    }
                >
                    <TableCell
                        style={mdcDisplayProps.cellStyle}
                        onClick={() => dispatch(toggleMissingDataCheck(rowProps))}
                        align="right">
                        {mdcDisplayProps.label}
                    </TableCell>
                </HtmlTooltip>
                <HtmlTooltip
                    title={
                        <React.Fragment>
                            <Typography color="inherit">Maintenance Mode</Typography>
                            <p>
                                <b>{"Normal Operations - "}</b>
                                {'All incoming data associated with this instrument will not be automatically flagged as invalid and it will undergo regular AutoQC checks.'}
                            </p>
                            <p>
                                <b>{"Planned Maintenance - "}</b>
                                {'All incoming data associated with this instrument will be flagged as invalid (QC Code 9) due to planned maintenance (OP Code 28). This will prevent it from being displayed on the public websites or from being included in the averaging for REL notifications.'}
                            </p>
                            <p>
                                <b>{"Urgent (Unplanned) Maintenance - "}</b>
                                {'All incoming data associated with this instrument will be flagged as invalid (QC Code 9) due to urgent (unplanned) maintenance (OP Code 29). This will prevent it from being displayed on the public websites or from being included in the averaging for REL notifications.'}
                            </p>
                        </React.Fragment>
                    }
                >
                    <TableCell
                        style={mmcDisplayProps.cellStyle}
                    >
                        <NormalOperationsButton
                            onClick={() => dispatch(toggleMaintenanceModeCheck({...rowProps, selectedMaintenance: MaintenanceModeOptionsEnum.NONE}))}
                            variant={!rowProps.maintenanceModeEnabled ? "contained" : "outlined"}
                        >
                            {!rowProps.maintenanceModeEnabled ? <ChevronRightIcon/> : null} Normal Operations
                        </NormalOperationsButton>
                        <Button
                            color="primary"
                            onClick={() => dispatch(toggleMaintenanceModeCheck({...rowProps, selectedMaintenance: MaintenanceModeOptionsEnum.PLANNED}))}
                            variant={rowProps.maintenanceModeEnabled && rowProps.maintenanceIsPlanned ? "contained" : "outlined"}
                        >
                            {rowProps.maintenanceModeEnabled && rowProps.maintenanceIsPlanned ? <ChevronRightIcon/> : null} Planned Maintenance
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => dispatch(toggleMaintenanceModeCheck({...rowProps, selectedMaintenance: MaintenanceModeOptionsEnum.URGENT}))}
                            variant={rowProps.maintenanceModeEnabled && !rowProps.maintenanceIsPlanned ? "contained" : "outlined"}
                        >
                            {rowProps.maintenanceModeEnabled && !rowProps.maintenanceIsPlanned ? <ChevronRightIcon/> : null} Urgent (Unplanned) Maintenance
                        </Button>
                    </TableCell>
                </HtmlTooltip>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0}} colSpan={6}>
                    <Collapse in={isOpen} timeout="auto" unmountOnExit>
                        <DslDetails stats={dsl.stats} />
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


function DslTableBody(props) {

    const tableData = stableSort(Object.values(props.agencyDslList), getComparator(props.order, props.orderBy)).map(function(dsl, i) {

        const mdcDisplayProps = getMdcDisplayProps(dsl.json.missingDataCheck, dsl.stats);

        const mmcDisplayProps = getMmcDisplayProps(dsl.json.maintenanceModeCheck);

        const rowProps = {
            agencyCode: props.agencyCode,
            uniqueKey: dsl.uniqueKey,
            maintenanceModeEnabled: dsl.json.maintenanceModeCheck.isEnabled,
            maintenanceIsPlanned: dsl.json.maintenanceModeCheck.isPlanned
        };

        return (
            <Row key={dsl.id} dsl={dsl} mdcDisplayProps={mdcDisplayProps} mmcDisplayProps={mmcDisplayProps} rowProps={rowProps} />
        );

    });

    return (
        <TableBody>
            {tableData}
        </TableBody>
    );


};


export function DslTable(props) {

    const classes = useStyles();

    const appData = useSelector(selectAppData);

    const uiState = useSelector(selectAppUi);

    const auth = useSelector(selectAuth);

    const agencyDslList = appData[props.agency.code];

    const [order, setOrder] = React.useState('asc');

    const [orderBy, setOrderBy] = React.useState('name');

    // Initial inventory load
    if ((!agencyDslList || agencyDslList.length === 0) && uiState.isLoading) {

        return (

            <div style={{display: 'block'}}>
                <div style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <CircularProgress  color="primary" />
                </div>
                <div style={{margin: '20px', display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                    Fetching inventory details. This may take a moment...
                </div>
            </div>

        );


    }

    // Loaded. Empty inventory
    else if (!agencyDslList || agencyDslList.length === 0) {

        return (

            <div style={{display: 'block'}}>
                <div style={{display:'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <SentimentVeryDissatisfiedIcon  color="secondary" fontSize="large" />
                </div>
                <div style={{margin: '20px', textAlign: "center"}}>
                    Instrument inventory not found. This is likely due to a configuration issue or because there are no instrument lists associated with this agency.
                    Please contact the system administrator for assistance.
                </div>
            </div>

        );

    }


    // Loaded. Inventory found
    else {

        const lastRefreshedDt = (auth._dt) ? moment(auth._dt) : "n/a";

        const handleRequestSort = (event, property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        };

        return (

            <div className={classes.root}>

                <Typography variant="h5" component="h5" style={{textAlign: "center"}}>
                    {props.agency.name}
                </Typography>

                <TableContainer component={Paper}>

                    <Table size="small" stickyHeader className={classes.table} aria-label="simple table">
                        <EnhancedTableHeader
                            classes={classes}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={agencyDslList.length}
                        />

                        <DslTableBody
                            agencyDslList={agencyDslList}
                            agencyCode={props.agency.code}
                            order={order}
                            orderBy={orderBy}
                        />

                    </Table>

                </TableContainer>


                <Box component="span" m={1}>

                    <Typography color="textSecondary" variant="body2" display="block" gutterBottom align="center">
                        Last Inventory Refreshed - {lastRefreshedDt.toLocaleString()}
                    </Typography>

                </Box>

            </div>
        );

    }

}
